import React from "react"
import { Link } from "components/Layout/Link"
import Layout from "components/Layout/de"
import Product from "components/Layout/Product/index"
import productImg from "./product.jpg"
import productIcon from "./SurfaceScanner.png"

export default function(props) {
  const description = (
    <React.Fragment>
      <p>
        Beflügeln Sie ihr Qualitätsmanagement mit dem MoonVision SurfaceScanner.
        Fehler auf unterschiedlichen Oberflächen werden vollautomatisiert
        visuell erkannt und Unterschieden. Das Ergebnis sind ideale Produkte und
        signifikant verkürzte Qualitätsprüfungen.
      </p>
      <h3>Highlights</h3>
      <ul>
        <li>Automatisierte Qualitätsprüfung unterschiedlicher Oberflächen</li>
        <li>
          Von gleichbleibender Qualität und verkürzter Durchlaufzeit profitieren
        </li>
        <li>Erkennung und Messung mittels Kamera & Software</li>
        <li>Höchste Genauigkeit & Komfort durch Künstliche Intelligenz</li>
        <li>Auswertungen an Folgesysteme oder visuelle Darstellung</li>
      </ul>
    </React.Fragment>
  )
  return (
    <Layout location={props.location}>
      <Product
        id="SurfaceScanner"
        title="Qualitätsprüfung von Oberflächen"
        subtitle="Industrielle Qualitätsprüfung von Oberflächen"
        description={description}
        productImg={productImg}
        productIcon={productIcon}
        productFeatures={[
          "Automatisierte Oberflächenprüfung",
          "Konstante hohe Produktionsqualität",
          "Verkürzte Durchlaufzeit",
        ]}
      >
        <React.Fragment>
          <p>
            MoonVision SurfaceScanner unterstützt Sie bei der vollautomatischen
            Inspektion von Oberflächen. Kunden profitieren von höchster
            Genauigkeit der visuellen Prüfung und manuellen Optimierbarkeit auf
            den individuellen Bedarf. Dabei können erkannte Abweichungen vom
            Idealzustand auch automatisch vermessen werden. Sparen Sie jetzt
            Zeit und erreichen Sie vollautomatisiert höhere Qualitätsansprüche.
          </p>
          <p>
            Durch die eingesetzte Künstliche Intelligenz werden bestehende
            Systeme im Hinblick auf die Prüfgenauigkeit in den Schatten gestellt
            – der Bewertungsprozess wird vollautomatisiert und Sie sparen
            wertvolle Zeit.
          </p>
          <p>
            Der MoonVision SurfaceScanner kann unterschiedliche Arten von
            Oberflächen prüfen - dort wo sie es benötigen:
          </p>
          <ul>
            <li>Straßenqualitätsprüfung</li>
            <li>Erstellung von Statistiken zur Begrünung</li>
            <li>Reinheitsprüfung von Belegen</li>
            <li>Produktprüfung in der Fertigung</li>
            <li>Wirksamkeitsprüfung von Reinigungsgeräten</li>
          </ul>
          <p>
            Der MoonVision SurfaceScanner kann an unterschiedlichen
            Produktionsschritten von der Wareneingangskontrolle bis hin zur
            Warenausgangskontrolle eingesetzt werden. Durch das autarke Design
            kann die Software in einen laufenden Fertigungsprozess bzw.
            vorhandene ERP-Systeme integriert werden.
          </p>
          <p>Nach der Kontrolle können die Ergebnisse ausserdem:</p>
          <ul>
            <li>
              Visuell dargestellt werden (Auf einem Monitor/Alarme/Ablage von
              jpg Dateien)
            </li>
            <li>Tabellarisch zusammengefasst werden</li>
            <li>
              Per APIs an Managementinformationssysteme weitergegeben werden
            </li>
            <li>Per APIs zur Prozesssteuerung eingesetzt werden</li>
          </ul>
          <p>Durch das MoonVision Konzept</p>
          <ul>
            <li>
              können individuelle Anpassungen selbstständig durchgeführt werden
            </li>
            <li>
              bleibt der Zugang und Kontrolle über die Erkennungsparameter bei
              ihnen
            </li>
          </ul>
          <h3>Einfach in der Anwendung</h3>
          <p>
            Aufgrund des Softwaredesigns ist eine rasche Inbetriebnahme des
            MoonVision SurfaceScanner in ihrem Unternehmen möglich. Visuelle
            Daten wie Bilder und Videos werden unter nutzung von Künstlicher
            Intelligenz zu einem Modell verbunden. Dieses ist für Sie entweder
            vor Ort oder über die Cloud abrufbar.
          </p>
          <p>
            Informieren Sie sich auch über unsere Produkte:
            <ul>
              <li>
                <Link to="/products/paintscanner" location={props.location}>
                  MoonVision PaintScanner
                </Link>
              </li>
              <li>
                <Link to="/products/woodscanner" location={props.location}>
                  MoonVision WoodScanner
                </Link>
              </li>
              <li>
                <Link to="/products/metalscanner" location={props.location}>
                  MoonVision MetalScanner
                </Link>
              </li>
            </ul>
          </p>
        </React.Fragment>
      </Product>
    </Layout>
  )
}
